import React, {useState} from "react"
import styled from "styled-components"
import { colors } from "../../styles/styles"
import LinkList from "../navigation/LinkList"
import Toggle from "./Toggle"
import SidesFlexWrapper from "../SidesFlexWrapper"

const Nav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 55px;
    box-shadow: 0px 3px 6px ${colors.grey};
    background: white;
    z-index: 1;

    div > a > h3 {
        margin: 10.5px 0;
        color: ${colors.main};
    }

    &.navIsOpen {
        height: 100vh;
    }
`

const Navigation = ({isActive}) => {
    const [navIsOpen, setNavIsOpen] = useState(false)
    return(
    <Nav className={navIsOpen ? "navIsOpen" : ""}>
        <SidesFlexWrapper>
            <a href="/">
                <h3 className="lead">Zur Altdeutschen</h3>
            </a>
            <Toggle navIsOpen={navIsOpen} setNavIsOpen={setNavIsOpen} />
            <LinkList navIsOpen={navIsOpen} isActive={isActive}/>
        </SidesFlexWrapper>
    </Nav>
    )
}


export default Navigation;