import React from "react"
import styled from "styled-components"

import Navigation from "../components/navigation/Navigation"
import { breakpoints } from "../styles/styles"

const HeaderDiv = styled.div``
const HeaderImg = styled.div`
    width: 100vw;
    height: calc(60vh - 55px);
    background-image: url(${props => props.headerImg});
    background-size: cover;
    background-position: center;

    @media screen and (min-width: ${breakpoints.l}px) {
        height: calc(80vh - 55px);
    }
`

const Header = ({headerImg, isActive}) => 
    <HeaderDiv>
        <Navigation isActive={isActive} />
        {/* <Banner /> */}
        <HeaderImg headerImg={headerImg}>
            {/* <img src={headerImg}></img> */}
        </HeaderImg>
    </HeaderDiv>


export default Header;