import React, {useState} from "react"
import {Link} from "gatsby"
import styled from "styled-components"
import { colors, spacers } from "../styles/styles"

const FooterButtonDiv = styled.div`
    > * > * {
        margin: 0;
        padding: 0;
    }

    > * > p {
        color: ${colors.main};
        
        font-size: 20px;
        line-height: 34px;
    }

    display: grid;
    grid-template-columns: 34px 1fr;
    column-gap: ${spacers.s}px;
    background: ${colors.hovermain};
    padding: 8px ${spacers.s}px;
    border-radius: 6px;
    border: 2px solid ${colors.main};
    transition: all 100ms;

    &:hover {
        background: ${colors.main};
        > * > p {
            color: white;
        }
    }
`

const FooterButton = ({icon, title, iconWeiss, link}) => {
const [iconToDisplay, setIconToDisplay] = useState(icon)
    return(
        <Link to={link} onMouseOver={() => setIconToDisplay(iconWeiss)} onMouseLeave={() => setIconToDisplay(icon)}onTouchStart={() => setIconToDisplay(iconWeiss)} onTouchEnd={() => setIconToDisplay(icon)}>
    <FooterButtonDiv>
        <div>
            <img src={iconToDisplay} alt="Icon" />
        </div>
        <div>
            <p>{title}</p>
        </div>
    </FooterButtonDiv>
</Link>
    )
}

export default FooterButton