import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { colors, breakpoints, spacers } from "../styles/styles"
import SidesWrapper from "./SidesWrapper"
import Wrapper from "./Wrapper"
import FooterButton from "./FooterButton"

const FooterElem = styled.footer`
  background: ${colors.main};

  * {
    color: white;
  }

  > *:nth-of-type(3) {
    padding-top: ${spacers.s}px;
    padding-bottom: ${spacers.s}px;
    background: white;
    * {
      color: ${colors.font};
    }
  }
`

const FooterFlex = styled.div`
  div:first-of-type {
    margin-bottom: ${spacers.s}px;
  }

  @media screen AND (min-width: ${breakpoints.m}px) {
    div:first-of-type {
      margin-bottom: 0;
    }
    div:nth-of-type(2) {
      text-align: right;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const FooterMenu = styled.div`
  background: ${colors.hovermain};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;

  &:-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  > div {
    // when button width was 220px and 4 buttons width: 1040px
    width: 900px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: ${spacers.m}px;

    > a {
      display: block;
      width: 260px;
      margin-right: ${spacers.m}px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media screen AND (min-width: 1030px) {
    > div {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }
`

const Footer = () => (
  <FooterElem>
    <FooterMenu>
      <div>
        <FooterButton
          link="/einzelzimmer"
          title="Einzelzimmer"
          icon="/assets/icons/einzelbett.svg"
          iconWeiss="/assets/icons/einzelbett-weiss.svg"
        />
        <FooterButton
          link="/doppelzimmer"
          title="Doppelzimmer"
          icon="/assets/icons/doppelbett.svg"
          iconWeiss="/assets/icons/doppelbett-weiss.svg"
        />
        <FooterButton
          link="/ferienwohnung-studio"
          title="Ferienwohnung"
          icon="/assets/icons/bett-2.svg"
          iconWeiss="/assets/icons/bett-2-weiss.svg"
        />
        {/* <FooterButton link="/ferienwohnung" title="Ferienwohnung" icon="/assets/icons/bett-4.svg" iconWeiss="/assets/icons/bett-4-weiss.svg" /> */}
      </div>
    </FooterMenu>
    <a href="https://goo.gl/maps/iQDbpJ3LdqS5tbgUA">
      <img src="/assets/img/map.png" alt="Karte" />
    </a>
    <Wrapper>
      <FooterFlex>
        <div>
          <address>
            <b>Pension "Zur Altdeutschen"</b>
            <br />
            Neumarkt 1<br />
            09405 Zschopau
            <br />
          </address>
        </div>
        <div>
          <address>
            <a href="tel:+49372522734">+49 3725 227 34</a>
            <br />
            <a href="tel:+4917612626043">+49 176 1262 60 43 (mobil)</a>
            <br />
            <a href="mailto:zuraltdeutschen@aol.com">zuraltdeutschen@aol.com</a>
            <br />
            ©2024 Pension Zur Altdeutschen
          </address>
        </div>
      </FooterFlex>
    </Wrapper>
    <div>
      <SidesWrapper>
        <p className="copyright">
          <Link to="/datenschutz">Datenschutz</Link> |{" "}
          <Link to="/impressum">Impressum</Link>
        </p>
      </SidesWrapper>
    </div>
  </FooterElem>
)

export default Footer
