import React from "react"
import styled from "styled-components"
import { breakpoints, colors, spacers } from "../../styles/styles"

const LinkListDiv = styled.div`
display: none;

&.navIsOpen {
    @media screen AND (max-width: ${breakpoints.l - 1}px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    background: white;
    height: calc(100vh - 55px);

    > a {
        padding: ${spacers.s}px;
        

    &:hover, &.isActive {
        background: ${colors.main};
        color: white;
    }

        @media screen and (min-width: ${breakpoints.m}px) {
            padding: ${spacers.m}px
        }
    }
    }
}

@media screen AND (min-width: ${breakpoints.l}px) {
    margin: auto 0;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 20px;
    
    a {
        text-decoration: none;
        border-bottom: 1px solid white;
    &:hover, &.isActive {
        border-bottom: 1px solid ${colors.main};
    }
}
    
}
`

const LinkList = ({navIsOpen, isActive}) =>
<LinkListDiv className={navIsOpen ? "navIsOpen" : ""}>
    <a href="/" className={isActive === "home" ? "isActive" : ""}>Home</a>
    <a href="/zimmer-und-fewo" className={isActive === "zimmer" ? "isActive" : ""}>Zimmer & FeWo</a>
    <a href="/ueber-uns" className={isActive === "about" ? "isActive" : ""}>Über uns</a>
    <a href="/kontakt" className={isActive === "kontakt" ? "isActive" : ""}>Kontakt</a>
</LinkListDiv>

export default LinkList