import React from "react"
import styled from "styled-components"
import { breakpoints, colors } from "../../styles/styles"

const ToggleDiv = styled.div`
    width: 34px;
    height: 34px;
    margin: 10.5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    z-index: 2;

    > div {
        background: ${colors.main};
        height: 3px;
        width: 34px;
        transition: all 300ms;

        &:nth-of-type(2) {
            position: absolute;
            transform: rotate(90deg);

        }
    }

    &.navIsOpen {
        > div:nth-of-type(2) {
            transform: rotate(0deg);

        }
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        display: none;
    }
`

const Toggle = ({navIsOpen, setNavIsOpen}) => 
<ToggleDiv className={navIsOpen ? "navIsOpen" : ""} onClick={() => setNavIsOpen(!navIsOpen)}>
    <div></div>
    <div></div>
</ToggleDiv>

export default Toggle