export const colors = {
    main: "#b8860b",//"#E9D362",
    grey: "#6E6E6E",
    font: "#332503", //20 lightness of main color,
    hovermain: "#F8F3E6" //10% opacity
}

export const breakpoints = {
    s: 400,
    m: 768,
    l: 1280
}

export const spacers = {
    s: 16,
    m: 32,
    l: 64
}