import React from "react"
import styled from "styled-components"
import {spacers, breakpoints} from "../styles/styles"

const WrapperDiv = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
        padding: ${spacers.m}px;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        padding: ${spacers.l}px;
    }
`

const Wrapper = ({children}) => <WrapperDiv>{children}</WrapperDiv>


export default Wrapper